<template>
  <div class="sales-account">
    <div class="page-header">
      <h4>
        Welcome Back ,
        <div class="name">{{ salesAccountName }}</div>
      </h4>
    </div>
    <div class="page-content">
      <div v-if="goal !== null && isDataComing === true">
        <div class="targets">
          <div class="quantum content">
            <div class="title">
              <h5>Cluster Quantum</h5>
            </div>
            <div class="details">
              <h5>
                {{
                  new Intl.NumberFormat("en-DE", {
                    style: "currency",
                    currency: "dhs",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(Math.round(goal)) ?? 0
                }}
              </h5>
            </div>
          </div>

          <div class="potentiality content">
            <div class="title">
              <h5>Potentiality</h5>
            </div>
            <div class="details">
              <h5>
                {{
                  new Intl.NumberFormat("en-DE", {
                    style: "currency",
                    currency: "dhs",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(Math.round(potentiality)) ?? 0
                }}
              </h5>
            </div>
          </div>
          <div class="awarded content">
            <div class="title">
              <h5>Awarded</h5>
            </div>
            <div class="details">
              <h5>
                {{
                  new Intl.NumberFormat("en-DE", {
                    style: "currency",
                    currency: "dhs",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(Math.round(totalOpportunityAwarded)) ?? 0
                }}
              </h5>
            </div>
          </div>
          <div class="conversion content">
            <div class="title">
              <h5>Conversion</h5>
            </div>
            <div class="details">
              <h5>{{ Math.round(totalConversion) }} %</h5>
            </div>
          </div>
          <div class="achievement content">
            <div class="title">
              <h5>Achievement</h5>
            </div>
            <div class="details">
              <h5>{{ Math.round(achievement) ?? 0 }}%</h5>
            </div>
          </div>
          <div class="impact content">
            <div class="title">
              <h5>Market Imapct</h5>
            </div>
            <div class="details">
              <h5 v-if="po === 0">0%</h5>
              <h5 v-else>{{ Math.round((this.awarded / this.po) * 100) }}%</h5>
            </div>
          </div>
        </div>
        <div
          class="account mt-3"
          v-for="(company, idx) in companies"
          v-bind:key="idx">
          <div class="account-name">
            <h3>{{ idx }}</h3>
          </div>
          <div class="tables__container d-flex">
            <div class="table__parent left_table w-40">
              <table class="w-100">
                <thead>
                  <tr>
                    <th class="comp__proposals"><h5>Proposals</h5></th>
                    <th class="comp__job-code"><h5>Job Code</h5></th>
                    <th class="comp__amount"><h5>Amount</h5></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, idx) in company" :key="idx">
                    <td class="comp__proposals">
                      <div>
                        {{ data.lead_title }}
                      </div>
                    </td>
                    <td class="comp__job-code">
                      <div class="name">
                        {{ data.jpb_code ?? "no job code" }}
                      </div>
                    </td>
                    <td class="comp__amount">
                      <div class="name">
                        {{
                          new Intl.NumberFormat("en-DE", {
                            style: "currency",
                            currency: "dhs",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(Math.round(data.Opportunity)) ?? 0
                        }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table__parent right_table w-60">
              <table class="w-100">
                <thead>
                  <tr>
                    <th><h5>Total Awarded</h5></th>
                    <th><h5>Conversion</h5></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, idx) in company" :key="idx">
                    <td v-if="idx === 0" class="comp__awards" rowspan="2">
                      <div class="name">
                        {{
                          new Intl.NumberFormat("en-DE", {
                            style: "currency",
                            currency: "dhs",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(Math.round(company.total_awarded)) ?? 0
                        }}
                      </div>
                    </td>
                    <td v-if="idx === 0" class="comp__conversion" rowspan="2">
                      <div class="name">
                        {{ Math.round(company.conversion) }}%
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="sum_container">
          <div class="sum_content">
            <h3>Total proposals</h3>
            <h3>
              {{
                new Intl.NumberFormat("en-DE", {
                  style: "currency",
                  currency: "dhs",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(Math.round(potentiality)) ?? 0
              }}
            </h3>
          </div>
        </div>
      </div>

      <div v-else>
        <h3 class="text-center pt-5 mb-5" style="color: #f47d1f">No Data</h3>
      </div>
    </div>
  </div>
</template>

<script>
import target from "../../api-services/target";
import axios from "axios";
import store from "../../store/store";

export default {
  name: "sales-account-page",
  mounted() {
    const d = new Date();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    this.salesAccountName = this.$store.state.auth.user.user.name;
    this.market = this.$store.state.auth.user.user.market_id;
    this.email = this.$store.state.auth.user.user.email;
    this.getClusterTarget();
    console.log(this.$store.state.auth.user.user)
  },
  data() {
    return {
      salesAccountName: null,
      isDataComing: false,
      market: null,
      email: null,
      month: null,
      year: null,
      goal: null,
      achievement: null,
      dataCoues: {},
      potentiality: 0,
      awarded: 0,
      po: 0,
      totalAwarded: 0,
      companies: [],
    };
  },
  methods: {
    async getClusterTarget() {
      try {
        const response = await target.getTargetByEmail(this.email);
        if (response.data !== "") {
          store.dispatch("setIsLoading", true);
          if (response.data.targets.length > 0) {
            this.goal = response.data.targets[0].target;

            await axios
              .post(process.env.VUE_APP_COUES_URL + "/salesViewAchievement", {
                email: this.email,
                month: this.month,
                year: this.year,
                market: this.market,
                goal: this.goal,
              })
              .then((responseCoues) => {
                store.dispatch("setIsLoading", true);
                if (responseCoues.data) {
                  // store.dispatch('setIsLoading', false);
                  if (responseCoues.data.po !== null) {
                    this.po = responseCoues.data.po;
                    this.achievement = responseCoues.data.po / this.goal;
                  } else {
                    this.po = 0;
                    this.achievement = 0;
                  }
                } else {
                  store.dispatch("setIsLoading", false);
                  this.achievement = 0;
                }
              });

            await axios
              .post(process.env.VUE_APP_COUES_URL + "/salesView", {
                email: this.email,
                month: this.month,
                year: this.year,
                // month: 12,
                // year: 2024,
                market: this.market,
                goal: this.goal,
              })
              .then((responseCouesData) => {
                store.dispatch("setIsLoading", true);
                if (responseCouesData.data) {
                  this.dataCoues = responseCouesData.data;
                  this.calculations(responseCouesData.data);
                  this.isDataComing = true;
                  store.dispatch("setIsLoading", false);
                } else {
                  store.dispatch("setIsLoading", false);
                  this.achievement = 0;
                }
              });
          }
        } else {
          store.dispatch("setIsLoading", false);
        }
      } catch (e) {
        console.log(e);
      }
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    decimalNumber(num) {
      return parseInt(Math.round(num));
    },
    calculations(data) {
      this.companies = data;
      this.totalOpportunityAwarded = 0;
      let totalTasks = 0;
      let totalAwardedTasks = 0;
      for (let property in data) {
        this.companies[property] = data[property];
        this.companies[property].total_awarded = 0;
        this.companies[property].awarded_count = 0;
        this.companies[property].total = 0;
        this.companies[property].conversion = 0;

        for (let i = 0; i < this.companies[property].length; i++) {
          if (this.companies[property][i].is_award === 1) {
            this.companies[property].awarded_count++;
            this.companies[property].total_awarded += parseFloat(
              this.companies[property][i].Opportunity
            );
            this.awarded += +data[property][i].Opportunity;
            this.totalOpportunityAwarded += parseFloat(
              this.companies[property][i].Opportunity
            ); // Add the Opportunity to the new variable if it is awarded
            totalAwardedTasks++;
          }
          this.companies[property].total += parseFloat(
            this.companies[property][i].Opportunity
          );
          this.potentiality += parseFloat(
            this.companies[property][i].Opportunity
          );
          totalTasks++;
        }
        this.companies[property].conversion =
          (this.companies[property].total_awarded /
            this.companies[property].total) *
          100;
      }
      this.totalConversion = (totalAwardedTasks / totalTasks) * 100 ?? 0 + "%";
    },
  },
};
</script>

<style scoped>
.sales-account table {
  border-collapse: collapse;
  border-style: hidden !important;
  color: #3c3c3b;
}
tr {
  display: table !important;
  width: 100%;
}
.sales-account table th {
  padding: 0.5em 1em;
}
.sales-account table h5 {
  font-size: 17px !important;
  font-weight: 700 !important;
}
.sales-account table tbody {
  /* border: 1px solid #13161c1a; */
  color: #737373;
}
.sales-account table td {
  border: 1px solid #13161c1a;
  padding: 0.5em 0em;
}
.sales-account table td div {
  padding: 0 1em;
}
.sales-account table td > *:not(:last-child) {
  border-bottom: 1px solid #13161c1a;
}
.tables__container {
  border: 1px solid #dadada;
  padding: 1em;
  margin-top: 0.5em;
  border-radius: 10px;
}
.table__parent {
  height: 18vh;
}
.left_table {
  width: 60%;
}
.right_table {
  width: 40%;
}
.comp__proposals {
  width: 25%;
}
.comp__job-code {
  width: 20%;
}
.comp__amount {
  width: 15%;
}
.comp__conversion,
.comp__awards {
  width: 20%;
  font-weight: 700;
  font-size: 25px;
  color: #f47d1f;
  text-align: center;
}
.right_table tbody tr td {
  height: 12vh;
}
.left_table table {
  display: block;
}
.left_table thead {
  display: block;
}
.left_table tbody {
  max-height: 15vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
}

.page-header {
  color: #3c3c3b;
  font-weight: 800;
  font-size: 20px;
}
.page-header .name {
  display: inline-block;
  color: #f47d1f;
  font-weight: 700;
}
.page-content {
  min-height: 100vh;
  border-radius: 5px;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
}
.targets {
  display: flex;
  justify-content: space-around;
  border: 1px solid #dadada;
  padding: 1em;
  margin-top: 0.5em;
  border-radius: 10px;
}
.content {
  width: 20%;
  display: flex;
  flex-direction: column;
}
.content .title h5 {
  color: #f47d1f;
  font-weight: 700 !important;
  font-size: 20px !important;
}
.content .details {
  color: rgba(19, 22, 28, 0.8);
  margin-top: 0.5em;
}

.account-name {
  background-color: rgba(19, 22, 28, 0.8);
  padding: 0.5rem;
  border-radius: 10px;
}
.account .account-name {
  text-align: center;
  color: #fff;
}
.sum_container {
  width: 100%;
  background-color: rgba(19, 22, 28, 0.8);
  padding: 0.5rem;
  border-radius: 10px;
  margin-top: 1rem;
  background: #ebebeb;
  color: #f47d1f;
}
.sum_content {
  display: flex;
  justify-content: space-between;
  padding: 1.5em;
}
.account-name h3 {
  font-weight: 800;
  font-size: 22px;
}
.sum_content h3 {
  font-weight: 800;
  font-size: 24px;
}
.sum_content h3:first-child {
  color: #3c3c3b;
}
</style>
