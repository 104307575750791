import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router/router";
import "bootstrap/dist/css/bootstrap.css";
import Toaster from "@meforma/vue-toaster";


import BootstrapVue from "../node_modules/bootstrap-vue-3";
import Datepicker from 'vue3-datepicker'
import PrimeVue from 'primevue/config'; // Import PrimeVue
// import 'primevue/resources/themes/saga-blue/theme.css'; // Import theme
// import 'primevue/resources/primevue.min.css'; // Import core styles
import 'primeicons/primeicons.css'; // Import PrimeIcons

import Calendar from 'primevue/calendar';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPen,
  faTrash,
  faChevronDown,
  faRightFromBracket,
  faHouse,
  faUser,
  faBriefcase,
  faLocationDot,
  faHandshake,
  faEye,
  faEyeSlash,
  faBook,
  faTriangleExclamation,
  faCodeBranch
} from "@fortawesome/free-solid-svg-icons";


//comment here again
library.add(
  faPen,
  faTrash,
  faChevronDown,
  faRightFromBracket,
  faHouse,
  faUser,
  faBriefcase,
  faLocationDot,
  faHandshake,
  faBook,
  faEye,
  faEyeSlash,
  faTriangleExclamation,
  faCodeBranch
);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

let app = createApp(App);
app.config.globalProperties.$APP_URL = "http://127.0.0.1:8000/api";

app
  .use(store)
  .use(router)
  .use(BootstrapVue).use(PrimeVue)
  .use(Toaster).use(PrimeVue).component('Calendar', Calendar)
  .component("font-awesome-icon", FontAwesomeIcon).component('Datepicker', Datepicker)
  .mount("#app");
