<template>
  <Navbar :isAccount="isAccount" />
  <div class="commercial-manager-screen">
    <div class="page-header">
      <h2>Hello, {{ role }}</h2>
    </div>

    <div class="tabs">
      <div class="tab">
        <button
          style="pointer-events: none"
          v-for="market in markets"
          :key="market.id"
          :class="'tablinks data' + market.name"
          @click="openCity($event, market.name)">
          {{ market.name }}
        </button>
      </div>

      <div id="GCC" class="tabcontent" style="display: block">
        <div class="tab-header">
          <div class="row flex-nowrap">
            <div class="market headers">
              <h5 class="">Market:</h5>
              <h6 class="details">{{ marketShow.name }}</h6>
            </div>
            <div class="month headers">
              <h5 class="">Month:</h5>
              <h6 class="details">{{ monthName }}</h6>
            </div>
            <div class="day-to-goal headers">
              <h5 class="">Days to goal:</h5>
              <h6 class="details">{{ dateGoal }} day/s</h6>
            </div>
          </div>
          <div class="row my-3">
            <div class="month headers">
              <h5 class="">Business Unit:</h5>
              <h6 class="details">{{ businessUnitShow.name }}</h6>
            </div>
            <div class="day-to-goal headers">
              <h5 class="">Date:</h5>
              <h6 class="details">{{ fullYear }}</h6>
            </div>
          </div>
        </div>

        <table
          class="table-container"
          v-if="Object.keys(clusterShow).length !== 0">
          <tr>
            <th class="table-header">Clusters</th>
            <th class="table-header">Goal</th>
            <th class="table-header">Pipeline</th>
            <th class="table-header">P.O</th>
            <th class="table-header">Invoice</th>
            <th class="table-header">Conversion</th>
            <th class="table-header">Achievement</th>
            <th class="table-header">Envision</th>
            <th class="table-header">Cluster Quantum Index</th>
          </tr>

          <tr v-for="(cluster, idx) in clusterShow" :key="idx">
            <td class="table-details">
              <span>{{ cluster.name }}</span>
            </td>
            <td class="table-details">
              <span>{{ decimalNumber(cluster.goal) }}</span>
            </td>
            <td class="table-details">
              <span v-if="cluster.pipline === null">0</span>
              <span v-else>{{ decimalNumber(cluster.pipline) }}</span>
            </td>
            <td class="table-details">
              <span v-if="cluster.po === null">0</span>
              <span v-else>{{ decimalNumber(cluster.po) }}</span>
            </td>
            <td class="table-details">
              <span v-if="cluster.invoice === null">0</span>
              <span v-else>{{ decimalNumber(cluster.invoice) }}</span>
            </td>
            <td class="table-details">
              <span v-if="cluster.pipline === null || cluster.pipline === 0"
                >0 %</span
              >
              <span v-else-if="cluster.po === null || cluster.po === 0"
                >0 %</span
              >
              <span v-else>{{
                Math.round(cluster.pipline / cluster.po) * 100 + " %"
              }}</span>
            </td>
            <td class="table-details">
              <span v-if="cluster.po === null">0 %</span>
              <span v-else-if="cluster.goal === 0">0 %</span>
              <span v-else>{{
                Math.round(cluster.po / cluster.goal) * 100 + " %"
              }}</span>
            </td>
            <td class="table-details">
              <span>{{ decimalNumber(cluster.envision) }}</span>
            </td>
            <td class="table-details">
              <span v-if="cluster.quantum_index === null">0</span>
              <span v-else>{{ decimalNumber(cluster.quantum_index) }} $</span>
            </td>
          </tr>
          <tr>
            <td class="table-details total__td">
              <span>Total Market Performance</span>
            </td>
            <td class="table-details total__td">
              <span>{{ decimalNumber(totalGoals) }}</span>
            </td>
            <td class="table-details total__td">
              <span>{{ decimalNumber(totalPipelines) }}</span>
            </td>
            <td class="table-details total__td">
              <span>{{ decimalNumber(totalPos) }}</span>
            </td>
            <td class="table-details total__td">
              <span>{{ decimalNumber(totalInvoices) }}</span>
            </td>
            <td class="table-details total__td">
              <span>{{
                totalConversion !== "NaN" || totalConversion !== "Infinity"
                  ? decimalNumber(totalConversion) + "%"
                  : 0 + "%" 
              }}</span>
            </td>
            <td class="table-details total__td">
              <span>{{
                totalAchievements !== "NaN"
                  ? decimalNumber(totalAchievements) + " %"
                  : 0 + " %"
              }}</span>
            </td>
            <td class="table-details total__td">
              <span>{{ decimalNumber(totalEnvisions) }}</span>
            </td>
            <td class="table-details total__td">
              <span>{{ decimalNumber(totalQuantumIndex) }} $</span>
            </td>
          </tr>
        </table>

        <!-- <div v-if="Object.keys(clusterShow).length !== 0">
          <div class="data-table d-flex">
            <div class="clusters sec">
              <h5>Clusters</h5>
              <div
                class="cluster-details"
                v-for="(cluster, idx) in clusterShow"
                :key="idx"
              >
                <span>{{
                  cluster.name.slice(0, 30) +
                  (cluster.name.length > 30 ? "..." : "")
                }}</span>
              </div>
              <div class="cluster-details">
                <span>Total Market Performance</span>
              </div>
            </div>
            <div class="goal sec">
              <h5>Goal</h5>
              <div
                class="cluster-details"
                v-for="(cluster, idx) in clusterShow"
                :key="idx"
              >
                <span>{{ decimalNumber(cluster.goal) }}</span>
              </div>
              <div class="cluster-details">
                <span>{{ decimalNumber(totalGoals) }}</span>
              </div>
            </div>
            <div class="pipeline sec">
              <h5>Pipeline</h5>
              <div
                class="cluster-details"
                v-for="(cluster, idx) in clusterShow"
                :key="idx"
              >
                <span v-if="cluster.pipline === null">0</span>
                <span v-else>{{ decimalNumber(cluster.pipline) }}</span>
              </div>
              <div class="cluster-details">
                <span>{{ decimalNumber(totalPipelines) }}</span>
              </div>
            </div>
            <div class="PO sec">
              <h5>P.O</h5>
              <div
                class="cluster-details"
                v-for="(cluster, idx) in clusterShow"
                :key="idx"
              >
                <span v-if="cluster.po === null">0</span>
                <span v-else>{{ decimalNumber(cluster.po) }}</span>
              </div>
              <div class="cluster-details">
                <span>{{ decimalNumber(totalPos) }}</span>
              </div>
            </div>
            <div class="invoice sec">
              <h5>Invoice</h5>
              <div
                class="cluster-details"
                v-for="(cluster, idx) in clusterShow"
                :key="idx"
              >
                <span v-if="cluster.invoice === null">0</span>
                <span v-else>{{ decimalNumber(cluster.invoice) }}</span>
              </div>
              <div class="cluster-details">
                <span>{{ decimalNumber(totalInvoices) }}</span>
              </div>
            </div>
            <div class="conversion sec">
              <h5>Conversion</h5>
              <div
                class="cluster-details"
                v-for="(cluster, idx) in clusterShow"
                :key="idx"
              >
                <span v-if="cluster.pipline === null || cluster.pipline === 0"
                  >0 %</span
                >
                <span v-else-if="cluster.po === null || cluster.po === 0"
                  >0 %</span
                >
                <span v-else>{{
                  Math.round((cluster.pipline / cluster.po) * 100) + " %"
                }}</span>
              </div>
              <div class="cluster-details">
                <span>{{
                  totalConversion !== "NaN" || totalConversion !== "Infinity"
                    ? decimalNumber(totalConversion) + "%"
                    : 0 + "%"
                }}</span>
              </div>
            </div>
            <div class="achievement sec">
              <h5>Achievement</h5>
              <div
                class="cluster-details"
                v-for="(cluster, idx) in clusterShow"
                :key="idx"
              >
                <span v-if="cluster.po === null">0 %</span>
                <span v-else-if="cluster.goal === 0">0 %</span>
                <span v-else>{{
                  (Math.round((cluster.po / cluster.goal) * 100) + " %") ?? 0 
                }}</span>
              </div>
              <div class="cluster-details">
                <span>{{
                  totalAchievements !== "NaN"
                    ? decimalNumber(totalAchievements) + " %"
                    : 0 + " %"
                }}</span>
              </div>
            </div>
            <div class="envision sec">
              <h5>Envision</h5>
              <div
                class="cluster-details"
                v-for="(cluster, idx) in clusterShow"
                :key="idx"
              >
                <span>{{ decimalNumber(cluster.envision) }}</span>
              </div>
              <div class="cluster-details">
                <span>{{ decimalNumber(totalEnvisions) }}</span>
              </div>
            </div>
            <div class="cluster-quantum-index sec">
              <h5>Cluster Quantum Index</h5>
              <div
                class="cluster-details"
                v-for="(cluster, idx) in clusterShow"
                :key="idx"
              >
                <span v-if="cluster.quantum_index === null">0</span>
                <span v-else>{{ decimalNumber(cluster.quantum_index) }} $</span>
              </div>
              <div class="cluster-details">
                <span>{{ decimalNumber(totalQuantumIndex) }} $</span>
              </div>
            </div>
          </div>
        </div> -->
        <div class="no-data" v-else>
          <h3 class="text-center">No Data</h3>
        </div>
      </div>
    </div>

    <div class="footer">
      <footer class="text-center mt-2 text-white footer">
        <p>
          Copyright © {{ yearBefore }}-{{ yearNow }} Caduceuslane.com. All
          rights reserved.
        </p>
      </footer>
    </div>
  </div>
</template>

<style>
.no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eca715;
  padding: 15%;
}

.footer {
  /* position: absolute; */
  bottom: 0px;
  right: 0;
  left: 0;
}

.commercial-manager-screen {
  background-image: url("../../assets/images/home-bg.png");
  min-height: calc(100vh - 100px);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;

  /* position: relative; */
}

.commercial-manager-screen .tabs {
  height: 77vh;
  /* padding-top: 1rem; */
  padding: 1rem 2rem 0;
}

.commercial-manager-screen .tab {
  border: none !important;
  display: flex;
}

.commercial-manager-screen .tab button.active {
  background-color: #eca715 !important;
  color: #13161c !important;
}

.commercial-manager-screen .tab button {
  border: none !important;
  width: 10% !important;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.5rem;
  background-color: #3c3c3b;
  color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.commercial-manager-screen .tabcontent {
  border: solid 1px #eca715;
  border-radius: 5px;
  min-height: 70vh;
  height: 70vh;
  padding: 2rem;
  /* overflow-y: auto;
  overflow-x: hidden; */
}

.commercial-manager-screen .day-to-goal,
.month,
.market {
  width: 30% !important;
}

.commercial-manager-screen .headers {
  display: flex;
  align-items: center;
}

.commercial-manager-screen .headers h5 {
  color: #fff;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.commercial-manager-screen .details {
  color: #eca715;
  font-size: 1.2rem;
  margin-bottom: 0;
  word-break: break-all;
  width: 60%;
}

.commercial-manager-screen .sec {
  width: 10%;
  /* background-color: #ECA715; */
  height: 5vh;
  margin-right: 5px;
}

.commercial-manager-screen .sec h5 {
  padding: 0.5rem;
  background-color: #eca715;
  border-radius: 5px;
}

.commercial-manager-screen .sec .cluster-details {
  padding: 0.5rem;
  background-color: #4c515c9a;
  color: #fff;
  border: solid 1px #907142;
  border-radius: 5px;
  /* margin-bottom: 5px; */
}

.commercial-manager-screen .clusters {
  width: 15%;
}

.commercial-manager-screen .cluster-quantum-index {
  width: 15%;
}

.commercial-manager-screen .page-header {
  color: #fff;
  padding: 1rem 2rem 0;
}

/* ------------------------------------------------------ */
/* design test */

.table-container {
  width: 100%;

  margin-left: -10px;
}

.line {
  /* border: 2px solid transparent; */
  margin: 0.5rem;
}

.table-header {
  padding: 0.5rem;
  color: #3c3c3b;
  border-radius: 5px;
  border-bottom: 2px solid #dadada;
}

.table-details {
  padding: 0.5rem;
  color: #737373;
  border-right: 2px solid #dadada;
  border-bottom: 2px solid #dadada;

  /* border: solid 1px #907142; */
  /* border-radius: 5px; */
  /* margin-bottom: 5px; */
  /* padding: 10px; */
}
.total__td {
  background-color: #3c3c3b;
  color: #fff;
}
/* ------------------------------------------------------ */

/* .market-details{
        min-height: 35vh;
    } */

@media (width: 1535px) {
  .commercial-manager-screen .sec .cluster-details {
    font-size: 0.75rem;
  }

  .commercial-manager-screen .sec h5 {
    font-size: 1rem;
  }
}

@media (width: 1440px) {
  .commercial-manager-screen .sec .cluster-details {
    font-size: 0.75rem;
  }

  .commercial-manager-screen .sec h5 {
    font-size: 1rem;
  }
}

@media (width: 1050px) {
  .commercial-manager-screen .sec .cluster-details {
    font-size: 0.5rem;
  }

  .commercial-manager-screen .sec h5 {
    font-size: 0.7rem;
  }
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";
import market from "../../api-services/market";
import business_unit from "../../api-services/business_unit";
import target from "../../api-services/target";
import axios from "axios";
import moment from "moment";
import store from "../../store/store";
import $ from "jquery";

export default {
  name: "commercial-manager-screen",
  mounted() {
    this.role = this.$store.state.auth.roles;
    const d = new Date();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();

    this.quarter = Math.floor((this.month - 1) / 3) + 1; // Calculate the quarter of the year

    let lastDayOfQuarter = new Date(d.getFullYear(), this.quarter * 3, 0); // Last day of the quarter

    let today = new Date(d.getFullYear(), d.getMonth(), d.getDate()); // Today's date
    this.dateGoal = Math.ceil(
      (lastDayOfQuarter - today) / (1000 * 60 * 60 * 24)
    ); // Days remaining in the quarter

    this.monthName = d.toLocaleString("default", { month: "long" });
    this.fullYear = moment(d).format("DD MMM YYYY");
    this.yearNow = d.getFullYear();
    this.yearBefore = d.getFullYear() - 1;
    this.getMarkets();
  },
  data() {
    return {
      isAccount: true,
      role: null,
      markets: [],
      businessUnits: [],
      marketShow: {},
      curMarketIndex: -1,
      businessUnitShow: {},
      curBusinessUnitIndex: -1,
      market_id: null,
      business_unit_id: null,
      clusters: [],
      startNow: null,
      month: null,
      year: null,
      monthName: null,
      fullYear: null,
      emails: [],
      res: [],
      clusterShow: {},
      currentMarketNow: null,
      totalGoals: 0,
      totalPipelines: 0,
      totalPos: 0,
      totalInvoices: 0,
      totalConversion: 0,
      totalAchievements: 0,
      totalQuantumIndex: 0,
      totalEnvisions: 0,
      countClusters: 0,
      isActive: false,
      dateGoal: null,
      yearNow: null,
      yearBefore: null,
    };
  },
  methods: {
    changeStatus(market) {
      if (market === "GCC") {
        this.isActive = true;
        $(".dataGCC").addClass("active");
        $(".dataKSA").removeClass("active");
        $(".dataEGYPT").removeClass("active");
      } else if (market === "KSA") {
        this.isActive = false;
        $(".dataGCC").removeClass("active");
        $(".dataKSA").addClass("active");
        $(".dataEGYPT").removeClass("active");
      } else {
        this.isActive = false;
        $(".dataGCC").removeClass("active");
        $(".dataKSA").removeClass("active");
        $(".dataEGYPT").addClass("active");
      }
    },
    openCity(evt, cityName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getMarkets() {
      try {
        const response = await market.indexWithoutLoading();
        this.markets = response.data.data;
        this.getBusinessUnits();
      } catch (e) {
        console.log(e);
      }
    },
    async getBusinessUnits() {
      try {
        const response = await business_unit.allWithoutLoader();
        this.businessUnits = response.data.data;
        if (this.isActive === true) {
          $(".dataGCC").addClass("active");
        }
        this.showPreview();
      } catch (e) {
        console.log(e);
      }
    },
    async showPreview() {
      store.dispatch("setIsLoading", false);
      for (let market of this.markets) {
        this.changeStatus(market.name);
        await this.showBusinessUnits(market);
      }
      this.showPreview();
    },
    async showBusinessUnits(currentMarket) {
      this.marketShow = currentMarket;
      this.currentMarketNow = currentMarket.name;
      for (let businessUnit of this.businessUnits) {
        this.businessUnitShow = businessUnit;
        this.res = await target.getClustersReports(
          currentMarket.id,
          businessUnit.id,
          
        );
        if (this.res.data.data.length > 0) {
          for (let i = 0; i < this.res.data.data.length; i++) {
            this.emails.push(this.res.data.data[i].email);
          }
        } else {
          this.clusterShow = {};
        }
        let emails = Object.values(this.emails);

        if (this.emails.length > 0) {
          store.dispatch("setIsLoading", true);
          await axios
            .post(process.env.VUE_APP_COUES_URL + "/commericalScreen", {
              email: emails,
              month: this.month,
year: this.year,
              // month: 12,
              // year: 2024,

              market: currentMarket.id,
            })
            .then((response) => {
              if (response.data) {
                store.dispatch("setIsLoading", false);
                this.hanldeDataFormat(response.data);
              }
            });

          this.emails = [];
        }
        await this.sleep(20000);
      }
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    decimalNumber(num) {
      return parseInt(Math.round(num));
    },
    async hanldeDataFormat(data) {
      this.clusterShow = data;
      this.totalGoals = 0;
      this.totalPipelines = 0;
      this.totalPos = 0;
      this.totalQuantumIndex = 0;
      this.totalInvoices = 0;
      this.totalConversion = 0;
      this.totalAchievements = 0;
      this.totalEnvisions = 0;
      let emailsEnvisions = [];

      if (this.res.data.data.length > 0) {
        this.countClusters = this.res.data.data.length;
        for (let j = 0; j < this.res.data.data.length; j++) {
          emailsEnvisions.push(this.res.data.data[j].email);
          this.clusterShow[this.res.data.data[j].email].name =
            this.res.data.data[j].name;
          if (this.res.data.data[j].current_target !== null) {
            this.clusterShow[this.res.data.data[j].email].goal =
              this.res.data.data[j].current_target.target;
            this.totalGoals += +this.res.data.data[j].current_target.target;
          } else {
            this.clusterShow[this.res.data.data[j].email].goal = 0;
          }
        }
      }

      for (let k = 0; k < emailsEnvisions.length; k++) {
        await axios
          .post(process.env.VUE_APP_CITIUS_URL + "/envision", {
            email: emailsEnvisions,
            month: this.month,
            year: this.year,
          })
          .then((response) => {
            if (response.data) {
              this.clusterShow[this.res.data.data[k].email].envision =
                response.data[k].envision;
            }
          });
      }

      for (let i = 0; i < Object.keys(data).length; i++) {
        this.clusterShow[Object.keys(data)[i]].email = Object.keys(data)[i];

        if (this.clusterShow[Object.keys(data)[i]].pipline !== null) {
          this.totalPipelines +=
            +this.clusterShow[Object.keys(data)[i]].pipline;
        } else {
          this.clusterShow[Object.keys(data)[i]].pipline = 0;
        }

        if (this.clusterShow[Object.keys(data)[i]].po !== null) {
          this.totalPos += +this.clusterShow[Object.keys(data)[i]].po;
        } else {
          this.clusterShow[Object.keys(data)[i]].po = 0;
        }

        if (this.clusterShow[Object.keys(data)[i]].invoice !== null) {
          this.totalInvoices += +this.clusterShow[Object.keys(data)[i]].invoice;
        } else {
          this.clusterShow[Object.keys(data)[i]].invoice = 0;
        }

        if (this.clusterShow[Object.keys(data)[i]].quantum_index !== null) {
          this.totalQuantumIndex +=
            +this.clusterShow[Object.keys(data)[i]].quantum_index;
        } else {
          this.clusterShow[Object.keys(data)[i]].quantum_index = 0;
        }

        this.totalEnvisions += +this.clusterShow[Object.keys(data)[i]].envision;
      }

      if (this.totalPos !== 0) {
        this.totalConversion = Math.round(
          (this.totalPipelines / this.totalPos / this.countClusters) * 100
        );
      } else {
        this.totalConversion = 0;
      }

      if (this.totalGoals !== 0) {
        this.totalAchievements =
          (this.totalPos / this.totalGoals / this.countClusters) * 100;
      } else {
        this.totalAchievements = 0;
      }
    },
  },
  components: {
    Navbar,
  },
};
</script>
